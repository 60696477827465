/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
// import React from "react";
import { useHistory } from "react-router-dom";
import "./styles/Sidebar_r.css";
import "./styles/Aircraft_card.css";
import { useSelector, useDispatch } from "react-redux";
import {
  updateAircraftTow,
  updateAircraftRwl,
} from "../redux/slice";
import debounce from "lodash.debounce";
import { TailSpin } from "react-loader-spinner";

function AircraftCard({ aircraft }) {
  const [cardActive, setCardActive] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const minTow = 50000
  // const [minTow, setMinTow] = useState(0);
  // setMinTow(50000)

  // debounced values used for api call
  const [towd, setTowd] = useState(0);

  // define debounce functions
  const debouncedTow = useCallback(
    debounce((data) => setTowd(data), 200),
    []
  );

  const { aircraftCards, airportDetail } = useSelector((state) => state.slice); // Get the existing aircraft codes from the Redux state


  useEffect(() => {
    debouncedTow(aircraft.tow)
  }, []) // this is to trigger th change of towd when the ac card renders with initial values

  useEffect(() => {
    if (towd) {      
      setLoading(true);
      const fetchItem = async () => {
        if (!isNaN(towd) && towd > 0 ) {
          const fetchItem = await fetch(`/api/takeoff_calculator/${aircraft.aircraft_id},${towd},${airportDetail.amsl_m},15`).finally(() => {
            setLoading(false);
          });
          const rl = await fetchItem.json();
          updateRwlValue(aircraft.aircraft_id, Math.round(rl))
        }
      };
      fetchItem();
    }
  }, [towd]);

  // function to push tow value to reducer:
  const updateTowValue = (aircraft_id, newTow) => {
    dispatch(updateAircraftTow({ aircraft_id, newTow}));
  }
  // function to push rwl value to reducer:
  const updateRwlValue = (aircraft_id, newRwl) => {
    dispatch(updateAircraftRwl({ aircraft_id, newRwl}));
  }

  //function to remove the aircraft from url
  const removeAircraftFromURL = (aircraftCodeToRemove) => {
      // Filter out the specific aircraft object based on url_code
    const updatedAircraftCards = aircraftCards.filter(
    (aircraft) => aircraft.url_code !== aircraftCodeToRemove
    );
    const basePath = window.location.pathname.split("/").slice(0, 3).join("/"); // Get the base URL without the aircraft codes
    // Extract the url_code values from the updated aircraftCards array
    const updatedAcs = updatedAircraftCards.map((aircraft) => aircraft.url_code);
    const newPath = updatedAcs.join("&"); // Join the updated aircraft codes with "&" separator
    history.push(`${basePath}/${newPath}`); // Update the URL without reloading the page
  };

  useEffect(() => {
    if (aircraft?.aircraft_id) {
      setCardActive(true);
    }
    else setCardActive(false);
  }, [aircraft.aircraft_id]);

  function showCard() {
    return (
      <div className={`${cardActive ? "ac-card active" : "ac-card"} ${
        aircraft.rwl < airportDetail.max_runway_length_m
          ? "green"
          : aircraft.rwl > airportDetail.max_runway_length_m
          ? "red"
          : ""
      }`}>
        <div
          className="close"
          onClick={() => {
            removeAircraftFromURL(aircraft.url_code);
          }}
        ></div>
        <div className="ac-model-button" onClick={() => {history.push(`/aircraft/${aircraft.url_code}/`)}}>
          <div>{aircraft.manufacturer.manufacturer_name.toUpperCase()}{" "}
            {aircraft.model}-{aircraft.variant}  |  {aircraft.icao_code}</div>
          <div className="engine">
            {aircraft.engine.engine_model}
          </div>
        </div>
        <div className="calculator">
          <div className="card-same-row" data-toggle="tooltip" title="Take-off weight">
            <input
              className="ac-range"
              type="range"
              min={aircraft.oew}
              max={aircraft.mtow}
              value={aircraft.tow}
              onChange={(e) => {
                // if (e.target.value > minTow) 
                {
                  updateTowValue(aircraft.aircraft_id, parseFloat(e.target.value))
                  debouncedTow(parseFloat(e.target.value));
                }
              }}
            ></input>
            <input
              className="ac-input"
              type="text"
              value={aircraft.tow}
              onChange={(e) => {
                if (e.target.value > aircraft.oew && e.target.value < aircraft.mtow) {
                  updateTowValue(aircraft.aircraft_id, parseFloat(e.target.value))
                  // to lower the rate of calls to the api
                  debouncedTow(parseFloat(e.target.value));
                }
              }}
            ></input>
            <div className="units">kg</div>
          </div>
          <div className="rwl">
            Required runway length:{" "}
            <div className="value">
              {!loading ? (
                aircraft.rwl ? aircraft.rwl + " m" : "-"
              ) : (
                <TailSpin
                  color="#29417a"
                  height="20"
                  width="20"
                  visible={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }  

  if (aircraft?.aircraft_id) {
    return showCard();
  }

  return null;
}

export default AircraftCard;