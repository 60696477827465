import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "slice",
  initialState: {
    airportDetail: null,
    aircraftDetail: null,
    airportCollection: [],
    aircraftCards: [],
    // locate: 0,
    map: {
      longitude: 0,
      latitude: 20,
      zoom: 1.5,
    },
    disableMap: false,
    selectedRunway: "",
    test: 0,
  },
  reducers: {
    setMap: (state, action) => {
      state.map = action.payload;
    },
    disableMapPush: (state, action) => {
      state.disableMap = action.payload;
    },
    setAircraftDetail: (state, action) => {
      state.aircraftDetail = action.payload;
    },
    setAirportDetail: (state, action) => {
      state.airportDetail = action.payload;
    },
    setAirportCollection: (state, action) => {
      state.airportCollection = action.payload;
    },
    setAircraftCards: (state, action) => {
      state.aircraftCards = action.payload;
    },
    // action to update the 'tow' value of a specific aircraft card
    updateAircraftTow: (state, action) => {
      const { aircraft_id, newTow } = action.payload;
      state.aircraftCards = state.aircraftCards.map((ac) =>
        ac.aircraft_id === aircraft_id ? { ...ac, tow: newTow } : ac
      );
    },
    // action to update the 'rwl' value of a specific aircraft card
    updateAircraftRwl: (state, action) => {
      const { aircraft_id, newRwl } = action.payload;
      state.aircraftCards = state.aircraftCards.map((ac) =>
        ac.aircraft_id === aircraft_id ? { ...ac, rwl: newRwl } : ac
      );
    },
    // action to update the 'tow' value of a specific aircraft detail
    updateAircraftDetailTow: (state, action) => {
      const { aircraft_id, newTow } = action.payload;
      state.aircraftDetail?.aircraft_id === aircraft_id &&
        (state.aircraftDetail.tow = newTow);
    },
    // action to update the 'rwl' value of a specific aircraft detail
    updateAircraftDetailRwl: (state, action) => {
      const { aircraft_id, newRwl } = action.payload;
      state.aircraftDetail?.aircraft_id === aircraft_id &&
        (state.aircraftDetail.rwl = newRwl);
    },
    setAircraftCode: (state, action) => {
      state.aircraftCode = action.payload;
    },
    setSelectedRunway: (state, action) => {
      state.selectedRunway = action.payload;
    },
    setTest: (state, action) => {
      state.test = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAirportDetail,
  setAircraftDetail,
  setMap,
  disableMapPush,
  setAircraftCards,
  updateAircraftTow,
  updateAircraftRwl,
  updateAircraftDetailTow,
  updateAircraftDetailRwl,
  setSelectedRunway,
  setAirportCollection,
  setTest,
} = slice.actions;

export default slice.reducer;
