/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
// import { Link } from "react-router-dom";
import "./styles/Sidebar.css";
import "./styles/Aircraft_detail.css";
import { disableMapPush, updateAircraftDetailRwl, updateAircraftDetailTow } from "../redux/slice";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { TailSpin } from "react-loader-spinner";
// import { ReactComponent as Info } from "../data/info.svg";
// import { Oval } from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";

function AircraftDetail( {aircraft} ) {
  const { disableMap } = useSelector((state) => state.slice);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  // const [localAirports, setLocalAirports] = useState([]);
  // const [minTow, setMinTow] = useState(0);
  const [oat, setOat] = useState(15);
  const [amsl, setAmsl] = useState(0);
  //debounced values used for api call
  const [towd, setTowd] = useState(0);
  const [oatd, setOatd] = useState(15);
  const [amsld, setAmsld] = useState(0);
  const [loading, setLoading] = useState(false);

   // function to push tow value to reducer:
   const updateTowValue = (aircraft_id, newTow) => {
    dispatch(updateAircraftDetailTow({ aircraft_id, newTow}));
  }
  // function to push rwl value to reducer:
  const updateRwlValue = (aircraft_id, newRwl) => {
    dispatch(updateAircraftDetailRwl({ aircraft_id, newRwl}));
  }

  // const [setLoadingBulk] = useState(false);
  //custom take-off calculation

  // const [calculateBulk, setCalculateBulk] = useState(false);

  // useEffect(() => {
  //   if (airportCollection) setLocalAirports(airportCollection);
  // }, [airportCollection]);

  const updateDisableMap = (payload) => {
    dispatch(disableMapPush(payload));
  };

  const fetchRwl = async (aircraft_id, tow, amsl, oat) => {
    setLoading(true);
    try {
      const fetchRl = await fetch(`/api/takeoff_calculator/${aircraft_id},${tow},${amsl},${oat}`);
      const rl = await fetchRl.json();
      updateRwlValue(aircraft_id, Math.round(rl));
    } finally {
      setLoading(false);
    }
  };
  // initial set tow
  useEffect(() => {
    setTowd(aircraft.tow)
  }, []) // this is to trigger to change of towd when the ac card renders with initial values
  // when aircraft change update tow, and check if sidebar should be active
  useEffect(() => {
    if (aircraft) {
      setSidebar(true);
      updateTowValue(aircraft.aircraft_id, (aircraft.mtow - aircraft.oew) / 2 + aircraft.oew) // set default tow value to 50% of actual load
      updateDisableMap(true);
      setTowd(aircraft.tow)
    }
    else {
      setSidebar(false)
      updateDisableMap(false)
    }
  }, [aircraft.aircraft_id]);
  // when towd, amsld or oatd change, invoke the calculator
  useEffect(() => {
    if ( towd > 0) {
      fetchRwl(aircraft?.aircraft_id, towd, amsld, oatd);
    }
  }, [towd, amsld, oatd]);
  // this handles cases when the same ac is selected from the search box
  useEffect(() => {
    if (aircraft && !aircraft.rwl && towd > 0) {
      fetchRwl(aircraft?.aircraft_id, aircraft.tow, amsld, oatd);
    }
  }, [aircraft.rwl]);

  // TODO: api request for bulk takeoff calculator with loading indicator with all the airports in the range
  // TODO: this needs fixing
  // useEffect(() => {
  //   if (
  //     !(typeof item?.aircraft_id == "undefined" || item?.aircraft_id == null) &&
  //     disableMap == false &&
  //     calculateBulk &&
  //     !(typeof aircraftCode == "undefined")
  //   ) {
  //     const airports = airports.map(({ airport_id, amsl_m }) => {
  //       return {
  //         airport_id: airport_id,
  //         amsl_m: amsl_m,
  //         aircraft_id: item?.aircraft_id,
  //         tow: Number(towd),
  //         oat: Number(oatd),
  //       };
  //     });
  //     const calcJson = {
  //       airports: airports,
  //     };
  //     // TEMPORARY DISABLED
  //     // const salji = async () => {
  //     //   setLoadingBulk(true);
  //     //   const fetchItem = await fetch(`/api/takeoff_calculator_bulk/`, {
  //     //     method: "POST",
  //     //     body: JSON.stringify(calcJson),
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   }).finally(() => {
  //     //     setLoadingBulk(false);
  //     //   });

  //     //   const item = await fetchItem.json();
  //     //   dispatch(setAirportCollection(item));
  //     // };
  //     // salji();
  //     //----------------------

  //   }
  // }, [airportCollection, towd, amsld, oatd, disableMap]);

  // define debounce functions
  const debouncedTow = useCallback(
    debounce((data) => setTowd(data), 100),
    []
  );
  const debouncedOat = useCallback(
    debounce((data) => setOatd(data), 100),
    []
  );
  const debouncedAmsl = useCallback(
    debounce((data) => setAmsld(data), 100),
    []
  );

  // custom amsl calculation

  // const handleCheckbox = () => {
  //   setCalculateBulk(!calculateBulk);
  //   updateDisableMap(!disableMap);
  // };

  function showAircraft() {
    return (
      <div className={sidebar ? "sidebar active" : "sidebar"}>
        <Helmet>
          <title>
            {aircraft.manufacturer.manufacturer_name.toUpperCase()} | {aircraft.model}-
            {aircraft.variant} - Aircraft data | Avionia
          </title>
          <meta
            name="description"
            content={
              aircraft.manufacturer.manufacturer_name.toUpperCase() +
              " | " +
              aircraft.model +
              "-" +
              aircraft.variant +
              " Aircraft data. Runway length calculator. Take-off"
            }
          />
          <meta
            name="keywords"
            content={
              aircraft.manufacturer.manufacturer_name +
              ", " +
              aircraft.model +
              "-" +
              aircraft.variant +
              ", " +
              aircraft.icao_code +
              ", " +
              aircraft.iata_code +
              ", Aircraft data. Runway length calculator. Take-off"
            }
          />
          <link
            rel="canonical"
            href={"https://www.avionia.com" + window.location.pathname}
          />
        </Helmet>

        <div className="card-header-a header-ac">
          <div
              className="close"
              onClick={() => {
                updateDisableMap(false);
                history.push("/");
              }}
            ></div>
          <h1 className="card-same-row" data-toggle="tooltip" title="Aircraft model">
            {aircraft.manufacturer.manufacturer_name.toUpperCase()} {aircraft.model}-
            {aircraft.variant}
          </h1>
          <div>
            {aircraft.icao_code}|{aircraft.iata_code}
          </div>
        </div>
        <div className="card-engine" data-toggle="tooltip" title="Engine Option">
          <h2>{aircraft.engine.engine_model}</h2>
          <div>{aircraft.engine.manufacturer}</div>
        </div>
        <div className="card-thrust" data-toggle="tooltip" title="Take-off thrust per engine">
          {aircraft.engine.thrust_kn} KN
        </div>
        <div>
          <h2 className="performance-divider">Performance</h2>
          <table className="card-mass">
            <tbody>
              <tr>
                <th data-toggle="tooltip" title="Maximum take-off weight">
                  MTOW
                </th>
                <th data-toggle="tooltip" title="Maximum landing weight">
                  MLW
                </th>
                <th data-toggle="tooltip" title="Operating empty weight">
                  OEW
                </th>
              </tr>
              <tr>
                <td data-toggle="tooltip" title="Maximum take-off weight">
                  {aircraft.mtow} kg
                </td>
                <td data-toggle="tooltip" title="Maximum landing weight">
                  {aircraft.mlw} kg
                </td>
                <td data-toggle="tooltip" title="Operating empty weight">
                  {aircraft.oew} kg
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="calculator-divider">Runway length calculator</h3>
          <div className="calculator">
            <div className="card-same-row" data-toggle="tooltip" title="Take-off weight"
            >
              <input
                className="ac-range"
                type="range"
                min={aircraft.oew}
                max={aircraft.mtow}
                value={aircraft.tow}
                onChange={(e) => {
                  if (e.target.value > aircraft.oew) {
                    updateTowValue(aircraft.aircraft_id, parseFloat(e.target.value))
                    debouncedTow(e.target.value);
                  }
                }}
              ></input>
              <input
                className="ac-input"
                type="text"
                value={aircraft.tow}
                onChange={(e) => {
                  if (e.target.value > aircraft.oew && e.target.value < aircraft.mtow) {
                    updateTowValue(aircraft.aircraft_id, parseFloat(e.target.value))
                    debouncedTow(e.target.value);
                  }
                }}
              ></input>
              <div className="units">kg</div>
            </div>
            <div className="card-same-row" data-toggle="tooltip" title="Take-off outside air temperature">
              <input
                className="ac-range"
                type="range"
                min="-20"
                max="50"
                value={oat}
                onChange={(e) => {
                  setOat(e.target.value);
                  debouncedOat(e.target.value);
                }}
              ></input>
              <input
                className="ac-input"
                type="text"
                value={oat}
                onChange={(e) => {
                  if (e.target.value > -20 && e.target.value < 50) {
                    setOat(e.target.value);
                    debouncedOat(e.target.value);
                  }
                }}
              ></input>
              <div className="units">°C</div>
            </div>
            <div className="card-same-row" data-toggle="tooltip" title="Take-off airport elevation">
              <input
                className="ac-range"
                type="range"
                min="-100"
                max="6000"
                value={amsl}
                onChange={(e) => {
                  setAmsl(e.target.value);
                  debouncedAmsl(e.target.value);
                }}
                disabled={!disableMap}
              ></input>
              <input
                className="ac-input"
                type="text"
                value={amsl}
                onChange={(e) => {
                  if (e.target.value > -100 && e.target.value < 6000) {
                    setAmsl(e.target.value);
                    debouncedAmsl(e.target.value);
                  }
                }}
                disabled={!disableMap}
              ></input>
              <div className="units">m</div>
              {/* <div>
                <input
                  type="checkbox"
                  checked={disableMap}
                  onChange={handleCheckbox}
                />{" "}
                <Info className="info" data-tip="Set custom AMSL"></Info>
              </div> */}
            </div>
              <div className="runway-length">
                Required runway length:{" "}
                {!loading ? (
                  aircraft.rwl ? aircraft.rwl + " m" : "-"
                ) : (
                  <TailSpin
                    color="#29417a"
                    height="20"
                    width="20"
                    visible={true}
                  />
                )}
              </div>
            {/* TODO */}
            {/* <div className="ap-list">
              {localAirports.length ? (
                localAirports.map((airport, index) => (
                  <li className="ac" key={index}>
                    <Link
                      // onClick={() => addAircraft(ac.aircraft_url_code)}
                      to={
                        !window.location.pathname.includes(airport.icao)
                          ? `${window.location.pathname},${airport.icao}`
                          : `${window.location.pathname}`
                      }
                    >
                      <div>
                        {airport.icao} {airport.name}
                      </div>
                    </Link>
                  </li>
                ))
              ) : (
                <Oval />
              )}
            </div> */}
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
        </div>
      </div>
    );
  }

  if (aircraft?.aircraft_id) {
    return showAircraft();
  }

  return null;
}

export default AircraftDetail;
