/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Map from "../components/Map";
import {
  setMap,
  setAircraftCards,
  setAirportDetail,
  setAircraftDetail,
} from "../redux/slice";
import AirportDetail from "../components/Airport_detail";
import AircraftDetail from "../components/Aircraft_detail";
import AircraftCardHolder from "../components/Aircraft_card_holder";
import AirportCardHolder from "../components/Airport_card_holder";

function Home({ match }) {
  const { icao, ac_code, acs } = match.params;
  const { airportDetail, aircraftDetail, aircraftCards } = useSelector(
    (state) => state.slice
  );
  const dispatch = useDispatch();
  const aircraftCardsUrls = acs ? acs.split("&") : [];
  const [prevIcao, setPrevIcao] = useState(icao); // this is so that api is not called every time 'match' changes, only when 'icao' changes

  const fetchGeo = async () => {
    const fetchGeoApi = await fetch(`https://ipapi.co/json/`);
    const geo = await fetchGeoApi.json();
    // first check if there is an airport's icao in the url (and center the map on it):
    if (icao) {
      fetchAirport()
    }
    // then check if geoapi data are available:
    else if (geo.longitude != null) {
      dispatch(setMap({longitude: geo.longitude, latitude: geo.latitude, zoom: 6,}));
    }
  }

  const fetchAirport = async () => {
    if (icao) {
      const fetchAp = await fetch(`/api/airport/${icao}`);
      const ap = await fetchAp.json();
      if (Object.keys(ap).length !== 0) {
          dispatch(setAirportDetail(ap));
          dispatch(setMap({longitude: ap.longitude, latitude: ap.latitude, zoom: 12,}));
        }
    }
  };

  const fetchAircraft = async () => {
    if (ac_code) {
      const fetchAc = await fetch(`/api/aircraft/${ac_code}`);
      const ac = await fetchAc.json();      
      ac.tow = (ac.mtow - ac.oew) / 2 + ac.oew; // this sets the default tow value to 50% of actual load
      dispatch(setAircraftDetail(ac));
    }
  };

  const fetchAircraftCards = async () => { 
    // fetch only if the new aircraft URL does not exist in the current list
    const currentAcs = aircraftCards.map((aircraft) => aircraft.url_code) // Get the existing aircraft URLs - this is an array of objects
    if (aircraftCardsUrls) {
      const newAircraftUrls = aircraftCardsUrls.filter(
        (url) => !currentAcs.includes(url)
      );
      const removedAircraftUrls = currentAcs.filter(
        (url) => !aircraftCardsUrls.includes(url)
      );
      if (newAircraftUrls.length > 0) {        
        const aircraftCardsArray = [];
        for (const aircraftCardsUrl of newAircraftUrls) {
          const fetchAcInfo = await fetch(`/api/aircraft/${aircraftCardsUrl}`); // first fetch the aircraft details
          const acInfo = await fetchAcInfo.json();
          acInfo.tow = (acInfo.mtow - acInfo.oew) / 2 + acInfo.oew; // this sets the default tow value to 50% of actual load
          aircraftCardsArray.push(acInfo); 
          }
        dispatch(setAircraftCards([...aircraftCards, ...aircraftCardsArray]));
      }
      if (removedAircraftUrls.length > 0) {
        const filteredAircraftCards = aircraftCards.filter(
          (aircraft) => !removedAircraftUrls.includes(aircraft.url_code)
        );
        dispatch(setAircraftCards(filteredAircraftCards));
      }
    }
  }

  useEffect(() => {
    fetchGeo();
    }, []); // this makes sure that fetch geo is only run on the initial screen load if there is no icao in the url
  
  // orchestrating execution of fetch functions and switching app modes
  useEffect(() => {
    if (icao && !ac_code) { // airport mode
          if (icao !== prevIcao) { // only run if the icao value has changed
            fetchAirport();
            setPrevIcao(icao); // Remember the current 'icao' for the next comparison
            }
          dispatch(setAircraftDetail(null));   
          fetchAircraftCards()
        }
    else if (ac_code && !icao) { // aircraft mode
      dispatch(setAirportDetail(null));
      setPrevIcao(null)
      dispatch(setAircraftCards([]));
      fetchAircraft();      
    }
    else if (!ac_code && !icao) { // no mode
      dispatch(setAircraftDetail(null));
      dispatch(setAirportDetail(null));
      setPrevIcao(null)
    }
  }, [match]);

  return (
    <div className="home-container">
      {airportDetail && <AirportDetail airport={airportDetail} />}
      {aircraftDetail && <AircraftDetail aircraft={aircraftDetail} />}
      {airportDetail && <AircraftCardHolder />}
      {aircraftDetail && <AirportCardHolder />}
      <Map />
    </div>
  );
}

export default Home;